import * as React from 'react';
import classnames from 'classnames';
import { redirect, getBrowserStorage, setBrowserStorage, getCookieStorage } from '../../../common';
import envConstants from '../../../globals/constants/environment-constants';
import Dialog from '../dialog/Dialog';
import './ZipformFlowB.less';
import useWindowResize from '../../../hooks/useWindowResize';
import constants from '../../../globals/constants/application-constants';
import useSharedOepData from '../../../hooks/oepData';
import { AppContext } from '../../../globals/context/AppContext';

interface ILocation {
  cityName: string;
  countyName: string;
  fipsCode: string;
  savingsAmount: string;
  savingsLevel: string;
  ssacd: string;
  stateAbbr: string;
  stateName: string;
}

interface ZipformProps {
  fastQuote?: boolean;
  qaSection: string;
  insuranceType: string;
  position?: string;
  serviceHours: string;
}

const defaultProps = {
  fastQuote: false,
  position: null,
};

enum LocationErrorType {
  none,
  noCounty,
  zipEmpty,
  zipInvalid,
}

enum EmployeeErrorType {
  none,
  empty,
  invalid,
}

function Zipform({
  fastQuote,
  qaSection,
  insuranceType,
  position,
  serviceHours,
}: ZipformProps): JSX.Element {
  const [phoneNumberStyle, setPhoneNumberStyle] = React.useState('');
  const [zipValue, setZipValue] = React.useState('');
  const [countyValue, setCountyValue] = React.useState('');
  const [employeeValue, setEmployeeValue] = React.useState('');
  const [locationsUrl, setLocationsUrl] = React.useState('');
  const dynamicID = Math.random().toString().replace('.', '');
  // const [hasFetchedCounty, setHasFetchedCounty] = React.useState(false);
  const [isManualZipChange, setIsManualZipChange] = React.useState(false);
  const { stateAbbr, setStateAbbr } = useSharedOepData();
  const [isRequesting, setIsRequesting] = React.useState(false);
  const { phone, partnerAllianceInfo } = React.useContext(AppContext);

  React.useEffect(() => {
    setLocationsUrl(`${envConstants.MCWS_URL}`);
  }, []);

  React.useEffect(() => {
    if (
      partnerAllianceInfo?.data?.partnerAllianceInfo?.allianceData?.throttle === 'GREEN' &&
      phone.isFinal
    ) {
      setPhoneNumberStyle('opacity-100');
    } else if (
      ['RED', 'YELLOW'].includes(
        partnerAllianceInfo?.data?.partnerAllianceInfo?.allianceData?.throttle,
      )
    ) {
      setPhoneNumberStyle('hidden');
    } else {
      setPhoneNumberStyle('opacity-0');
    }
  }, [phone, partnerAllianceInfo]);

  const [hasLocationError, setHasLocationError] = React.useState(LocationErrorType.none);
  const [hasEmployeeError, setHasEmployeeError] = React.useState(EmployeeErrorType.none);

  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [multiCountyList, setMultiCountyList] = React.useState([] as ILocation[]);

  const form: React.MutableRefObject<HTMLFormElement> = React.useRef(null);
  const countySelect: React.MutableRefObject<HTMLSelectElement> = React.useRef(null);
  const dialogButton: React.MutableRefObject<HTMLButtonElement> = React.useRef(null);

  const fetchCounty = async (zip: string) => {
    try {
      const response = await fetch(`${locationsUrl}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query:
            'query areaByZipCode($zip: String!) {\n' +
            '    areaByZipCode(zipCode: $zip) {\n' +
            '        zip\n' +
            '        locationList {\n' +
            '            ssacd\n' +
            '            stateAbbr\n' +
            '            countyName\n' +
            '            fipsCode\n' +
            '            cityName\n' +
            '            stateName\n' +
            '        }\n' +
            '        hasError\n' +
            '        message\n' +
            '    }\n' +
            '}\n',
          variables: { zip },
        }),
      });
      const parsed: {
        data: {
          areaByZipCode: {
            zip: string;
            locationList: ILocation[];
            hasError: boolean;
            message: string;
          };
        };
      } = await response?.json();
      if (!response.ok || parsed.data.areaByZipCode.hasError) {
        throw new Error();
      }
      if (position !== 'footer' || isManualZipChange) {
        if (window._waEvents) {
          window._waEvents.push('trackEvent', {
            leadInfo: { zip },
            page: { action: 'Find Medicare Plans' },
          });
        }
      }
      setHasLocationError(LocationErrorType.none);

      if (parsed.data.areaByZipCode.locationList.length === 1) {
        setCountyValue(parsed.data.areaByZipCode.locationList[0].countyName);
        setBrowserStorage('zipValue', zip);
        setBrowserStorage('countyValue', parsed.data.areaByZipCode.locationList[0].countyName);
        setStateAbbr(parsed.data.areaByZipCode.locationList[0].stateAbbr);
      } else {
        if (position !== 'footer' || isManualZipChange) {
          if (window._waEvents) {
            window._waEvents.push('trackEvent', {
              page: { action: 'impression: county' },
            });
          }
        }
        setMultiCountyList(parsed.data.areaByZipCode.locationList);
        if (parsed.data.areaByZipCode.locationList.length > 1) {
          setStateAbbr(parsed.data.areaByZipCode.locationList[0].stateAbbr);
        }
      }
    } catch (e) {
      if (position !== 'footer' || isManualZipChange) {
        window._waEvents?.push('trackEvent', { form: { errors: 'invalid zip code' } });
      }
      setHasLocationError(LocationErrorType.zipInvalid);
    }
  };

  const toggleShowTooltip = () => {
    setIsDialogOpen(!isDialogOpen);
  };

  const filterKeyPress = (e: React.KeyboardEvent) => {
    if (
      '0123456789'.indexOf(e.key) > -1 ||
      e.key.length > 1 ||
      ((e.ctrlKey || e.metaKey) && ['a', 'z', 'x', 'c', 'v'].includes(e.key))
    ) {
      return;
    }
    e.preventDefault();
  };

  const handleZipChange = (e: React.SyntheticEvent) => {
    const { value } = e.target as HTMLInputElement;
    setIsManualZipChange(true);
    if (/\D/.test(value)) {
      setZipValue(zipValue);
    } else {
      setZipValue(value);
      if (hasLocationError > 1) {
        setHasLocationError(LocationErrorType.none);
      }
    }
  };

  const handleCountyChange = (e: React.SyntheticEvent) => {
    setCountyValue((e.target as HTMLOptionElement).value);
    if (hasLocationError === LocationErrorType.noCounty) {
      setHasLocationError(LocationErrorType.none);
    }
  };

  const handleEmployeeChange = (e: React.SyntheticEvent) => {
    const numValue = Number((e.target as HTMLInputElement).value);
    const strValue = numValue ? numValue.toString() : '';

    switch (hasEmployeeError) {
      case EmployeeErrorType.none: {
        setEmployeeValue(strValue);
        break;
      }
      case EmployeeErrorType.empty: {
        if (numValue > 0) {
          setHasEmployeeError(EmployeeErrorType.none);
          setEmployeeValue(strValue);
        }
        break;
      }
      case EmployeeErrorType.invalid: {
        if (numValue !== 1) {
          setHasEmployeeError(EmployeeErrorType.none);
          setEmployeeValue(strValue);
        }
        break;
      }
      // no default
    }
  };

  const validateForm = () => {
    let isValid = !hasLocationError && !(hasEmployeeError && insuranceType === 'smb-legacy');

    if (zipValue.length === 0) {
      isValid = false;
      window._waEvents?.push('trackEvent', { form: { errors: 'zip code is required' } });
      setHasLocationError(LocationErrorType.zipEmpty);
    } else if (!/^\d{5}$/.test(zipValue)) {
      isValid = false;
      window._waEvents?.push('trackEvent', { form: { errors: 'invalid zip code' } });
      setHasLocationError(LocationErrorType.zipInvalid);
    } else if (insuranceType === 'medicare' && hasLocationError < 2 && !countyValue) {
      isValid = false;
      window._waEvents?.push('trackEvent', { form: { errors: 'select your county' } });
      setHasLocationError(LocationErrorType.noCounty);
    }

    if (insuranceType === 'smb-legacy') {
      if (!employeeValue) {
        isValid = false;
        window._waEvents?.push('trackEvent', { form: { errors: 'employee number is required' } });
        setHasEmployeeError(EmployeeErrorType.empty);
      } else if (employeeValue === '1') {
        isValid = false;
        window._waEvents?.push('trackEvent', { form: { errors: 'invalid employee number' } });
        setHasEmployeeError(EmployeeErrorType.invalid);
      }
    }

    return isValid;
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }
    switch (insuranceType) {
      case 'medicare': {
        setBrowserStorage('zipValue', zipValue);
        setBrowserStorage('countyValue', countyValue);
        redirect.toEhmp({ zipValue, countyValue, fastQuote });
        break;
      }
      case 'smb-legacy': {
        setBrowserStorage('zipValue', zipValue);
        setBrowserStorage('employeeValue', employeeValue);
        redirect.toSmb({ zipValue, employeeValue });
        break;
      }
      case 'ifp': {
        setBrowserStorage('zipValue', zipValue);
        redirect.toIfp({ zipValue });
        break;
      }
      case 'st': {
        setBrowserStorage('zipValue', zipValue);
        redirect.toST({ zipValue });
        break;
      }
      // no default
    }
  };

  const handleSubmitForDTVS = (insType) => {
    switch (insType) {
      case 'dt':
        redirect.toDT({
          zipValue: getBrowserStorage('zipValue'),
        });
        break;
      case 'vs':
        redirect.toVS({
          zipValue: getBrowserStorage('zipValue'),
        });
        break;
      // no default
    }
  };

  const handleSubmitForSmb = (employerSize: string) => {
    switch (employerSize) {
      case 'small':
        redirect.toSmbLandingPage({
          zipValue: getBrowserStorage('zipValue'),
          employerSize,
        });
        break;
      case 'large':
        redirect.toSmbLandingPage({
          zipValue: getBrowserStorage('zipValue'),
          employerSize,
        });
        break;
      // no default
    }
  };

  React.useEffect(() => {
    let storedZip = getBrowserStorage('zipValue');
    const cookieZip = getCookieStorage('mockZipCode');
    if (storedZip) {
      setZipValue(storedZip);
    } else if (cookieZip) {
      storedZip = JSON.parse(cookieZip).zipCode;
      setZipValue(storedZip);
    }

    const storedEmployee = getBrowserStorage('employeeValue');
    if (storedEmployee) {
      setEmployeeValue(storedEmployee);
    }
  }, []);

  React.useEffect(() => {
    if (isRequesting) {
      return;
    }
    setMultiCountyList([]);
    setCountyValue('');
    const zipValueString = zipValue.toString();
    if (zipValueString && zipValueString.length === 5) {
      setIsRequesting(true);
      fetchCounty(zipValueString).finally(() => {
        setIsRequesting(false);
      });
    }
  }, [zipValue]);

  React.useEffect(() => {
    const storedCounty = getBrowserStorage('countyValue');

    if (multiCountyList.length && storedCounty && !countyValue) {
      const storedCountyMatch = (
        [].slice.call(countySelect.current.children) as HTMLOptionElement[]
      ).find((child) => {
        return child.value === storedCounty;
      })?.value;
      if (storedCountyMatch) {
        setCountyValue(storedCounty);
      }
    }
  }, [multiCountyList]);

  const showCountySelect = insuranceType === 'medicare' && multiCountyList.length > 1;
  const showEmployeeInput = insuranceType === 'smb-legacy';
  let buttonText = 'Compare Medicare plans';
  const windowInnerwidth = useWindowResize(true);
  const isDesktop = windowInnerwidth >= constants.DESKTOP_BREAKPOINT;
  const isMobile = windowInnerwidth < constants.TABLET_BREAKPOINT;

  let zipFormDisplayTemplate = 'default';
  switch (insuranceType) {
    case 'medicare':
      buttonText = 'Compare Medicare plans';
      break;
    case 'ifp':
      buttonText = 'Compare healthcare plans';
      break;
    case 'smb':
      zipFormDisplayTemplate = 'smb';
      break;
    case 'smb-legacy':
      buttonText = 'Compare healthcare plans';
      break;
    case 'st':
      buttonText = 'Compare short-term plans';
      break;
    case 'dental vision':
      zipFormDisplayTemplate = 'dental vision';
      break;

    default:
      buttonText = 'Compare healthcare plans';
      break;
  }

  return (
    <form ref={form} className="zipform flex flex-wrap text-18" data-qa={`${qaSection}:Zipform`}>
      {/* begin zipcode input */}
      <div
        className={`zip-container ${
          showEmployeeInput && !isMobile ? 'w-1/2 md:w-1/4 md:grow-0' : 'w-full md:w-auto md:grow-1'
        } pr-0 md:pr-6 ${
          insuranceType === 'dental vision' || insuranceType === 'smb' ? 'hidden' : ''
        }`}
      >
        <label htmlFor="zipcode" className="relative form-label">
          {isDesktop ? 'Enter your ZIP Code' : 'ZIP Code'}
          <button
            data-qa={`${qaSection}:Zipform:InfoButton`}
            type="button"
            className="ml-1 icon-info w-5 h-5 bg-no-repeat"
            aria-label="More info about zip code"
            ref={dialogButton}
            onClick={toggleShowTooltip}
          />
          <Dialog
            isOpen={isDialogOpen}
            onClick={toggleShowTooltip}
            parentRef={dialogButton}
            content="Your ZIP Code allows us to filter for plans in your area."
            qaSection={qaSection}
          />
        </label>
        <input
          className={`form-element h-[48px] ${
            hasLocationError > 1
              ? 'bg-red-light border-red focus:border-2 focus:outline-none'
              : 'border-gray-dark'
          }`}
          data-qa={`${qaSection}:Zipform:ZipInput`}
          name="zipcode"
          id={`zipcode-${dynamicID}`}
          aria-label="zip code"
          placeholder="ZIP Code"
          maxLength={5}
          pattern="[0-9]*"
          value={zipValue}
          onKeyPress={filterKeyPress}
          onChange={handleZipChange}
        />
        <div
          className={`error-msg icon-error-red ${
            insuranceType === 'smb-legacy' ? 'md:absolute' : ''
          } ${hasLocationError > 1 ? 'flex' : 'hidden'}`}
        >
          {hasLocationError === LocationErrorType.zipInvalid
            ? 'Invalid ZIP Code'
            : 'ZIP Code is required'}
        </div>
      </div>
      {/* end zipcode input */}

      {/* begin county select */}
      <div
        className={`county-container w-full md:w-auto mt-4 md:mr-6 md:mt-0 ${
          showCountySelect ? 'block' : 'hidden'
        }`}
      >
        <label htmlFor="county" className="form-label">
          County
        </label>
        <select
          className={`form-element h-[48px] ${
            hasLocationError === LocationErrorType.noCounty
              ? 'bg-red-light border-red focus:border-2 focus:outline-none'
              : 'border-gray-dark'
          }`}
          data-qa={`${qaSection}:Zipform:CountySelect`}
          id={`county-${dynamicID}`}
          name="county"
          onChange={handleCountyChange}
          ref={countySelect}
          value={countyValue}
        >
          <option value="" disabled>
            Select...
          </option>
          {multiCountyList?.map((county) => {
            return (
              <option key={`${county.ssacd}_${county.countyName}`} value={county.countyName}>
                {county.countyName}
              </option>
            );
          })}
        </select>
        <div
          className={`error-msg icon-error-red ${
            hasLocationError === LocationErrorType.noCounty ? 'flex' : 'hidden'
          }`}
        >
          Select your county
        </div>
      </div>
      {/* end county select */}

      {/* begin employee number input */}
      <div
        className={`employee-container ${
          isMobile ? 'w-full mr-0 mt-2' : 'w-1/4'
        } md:w-auto md:mr-6 md:mt-0 ${showEmployeeInput ? 'block' : 'hidden'}`}
      >
        <label
          htmlFor="employees"
          className="form-label whitespace-nowrap"
          aria-label="number of employees, including yourself"
        >
          <span>{isDesktop ? 'Number' : 'No.'} of Employees</span>
        </label>
        <input
          className={`form-element h-[48px] ${
            hasEmployeeError
              ? 'bg-red-light border-red focus:border-2 focus:outline-none'
              : 'border-gray-dark'
          }`}
          data-qa={`${qaSection}:Zipform:EmployeeCount`}
          name="employees"
          id={`employees-${dynamicID}`}
          aria-label="number of employees"
          pattern="[0-9]*"
          placeholder="Employees"
          value={employeeValue}
          onKeyPress={filterKeyPress}
          onChange={handleEmployeeChange}
        />
        <div className={`error-msg icon-error-red ${hasEmployeeError ? 'flex' : 'hidden'}`}>
          {hasEmployeeError === EmployeeErrorType.empty
            ? 'Required'
            : `Group health insurance requires at least one full-time employee other than you (i.e., the owner) and your spouse.
              If it's just you, let's find you low rates on individual and family plans.`}
        </div>
      </div>
      {/* end employee number input */}

      {/* begin submit button */}
      {zipFormDisplayTemplate === 'dental vision' && (
        <div className="zip-button-container w-full text-center">
          <div className="h-0 md:h-6 mb-1" />
          <div className="w-full md:w-1/2 md:inline-block pb-4 md:pb-0 md:pr-4">
            <button
              data-qa={`${qaSection}:Zipform:Submit`}
              data-wa-link={`${position === 'footer' ? 'footer ' : ''}dental: find plans`}
              type="button"
              onClick={() => {
                handleSubmitForDTVS('dt');
              }}
              className="bg-[#FA6200] btn-submit rounded-3xl font-poppin w-full"
            >
              Compare dental plans
            </button>
          </div>
          <div className="w-full md:w-1/2 md:inline-block">
            <button
              data-qa={`${qaSection}:Zipform:Submit`}
              data-wa-link={`${position === 'footer' ? 'footer ' : ''}vision: find plans`}
              type="button"
              onClick={() => {
                handleSubmitForDTVS('vs');
              }}
              className="bg-[#FA6200] btn-submit rounded-3xl font-poppin w-full"
            >
              Compare vision plans
            </button>
          </div>
          <div className="h-6" />
        </div>
      )}

      {zipFormDisplayTemplate === 'smb' && (
        <div className="zip-button-container w-full text-center">
          <div className="h-0 mb-1" />
          <div>
            <div className="font-poppin font-bold text-14 leading-20 md:text-24 md:leading-32 mb-3 text-center">
              How many employees do you have?
            </div>
            <div className="w-full md:w-1/3 md:inline-block pb-4 md:pb-0 md:pr-4">
              <button
                data-qa={`${qaSection}:Zipform:Small:Submit`}
                data-wa-link={`${position === 'footer' ? 'footer ' : ''}smb: 2-49 employees`}
                type="button"
                onClick={() => {
                  handleSubmitForSmb('small');
                }}
                className="bg-[#FA6200] btn-submit rounded-3xl font-poppin w-full"
              >
                2 &ndash; 49 Employees
              </button>
            </div>
            <div className="w-full md:w-1/3 md:inline-block">
              <button
                data-qa={`${qaSection}:Zipform:Large:Submit`}
                data-wa-link={`${position === 'footer' ? 'footer ' : ''}smb: 50+ employees`}
                type="button"
                onClick={() => {
                  handleSubmitForSmb('large');
                }}
                className="bg-[#FA6200] btn-submit rounded-3xl font-poppin w-full"
              >
                50+ Employees
              </button>
            </div>
            <div className="hidden md:block mt-6 font-poppin">
              <span className="text-green-800 text-18 leading-28 md:inline-block lg:inline md:w-full lg:w-auto">
                Talk to a licensed benefit advisor
              </span>
              <span className="text-green-800 text-24 leading-32 font-bold px-3 md:inline-block lg:inline md:w-full lg:w-auto">
                <span className="invoca-phone-number phone-number">{constants.DEFAULT_PHONE}</span>
              </span>
              <span className="text-12 text-left leading-16 md:inline-block lg:inline-block md:w-full lg:w-auto">
                {serviceHours}
              </span>
            </div>
          </div>
          <div className={classnames(['h-6', position === 'footer' ? 'md:h-6' : 'md:h-1'])} />
        </div>
      )}

      {zipFormDisplayTemplate === 'default' && (
        <div className="zip-button-container w-full md:w-1/2 ">
          {/* for non DT/VS */}
          <div className="h-6 mb-1" />
          <button
            data-qa={`${qaSection}:Zipform:Submit`}
            data-wa-link={
              insuranceType === 'st'
                ? `${position === 'footer' ? 'footer ' : ''}st: find short term plans`
                : `${position === 'footer' ? 'footer ' : ''}${insuranceType}: find plans`
            }
            type="submit"
            onClick={handleSubmit}
            className="bg-[#FA6200] btn-submit rounded-3xl font-poppin w-full"
          >
            {buttonText}
          </button>
          <div className="h-6" />
        </div>
      )}
      {/* end submit button */}

      {!isMobile && insuranceType === 'medicare' && (
        <div
          className={classnames(
            'w-full text-center transition-opacity duration-500',
            phoneNumberStyle,
          )}
        >
          <p className="text-18 leading-28 font-open-sans">
            Or speak with an eHealth licensed insurance agent
          </p>
          <p>
            <span className={classnames('text-40 font-bold text-green-800')}>
              {phone.phoneNumber}
            </span>
            <span className="text-40">{' TTY 711 '}</span>
          </p>
        </div>
      )}
      {isMobile && insuranceType === 'medicare' && (
        <div
          className={classnames(
            'w-full text-center transition-opacity duration-500 mb-6',
            phoneNumberStyle,
          )}
        >
          <a
            data-qa={`${qaSection}:phoneNumber`}
            type="button"
            href={`tel:${phone.phoneNumber}`}
            className="bg-[#FFFFFF] text-green-800 border-2 border-green-800 rounded-3xl font-poppin w-full inline-block py-[7px] px-4 font-bold"
          >
            {phone.phoneNumber}
          </a>
          <p className="text-14 leading-20 font-open-sans mt-3">
            Or speak with an eHealth licensed insurance agent
          </p>
        </div>
      )}
    </form>
  );
}

Zipform.defaultProps = defaultProps;

export default Zipform;
