import React, { useState } from 'react';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { withStyles } from '@material-ui/styles';

import Zipform from '../core/zipform/ZipformFlowB';
import tabs from '../hero/components/TabData';
import useSharedTabState from '../../hooks/tabState';

interface ExpansionTabProps {
  position?: string;
  tab: any;
  index: number;
  qaSection: string;
  serviceHours: string;
}
const defaultProps = {
  position: null,
};
function ExpansionTab({
  position,
  tab,
  index,
  qaSection,
  serviceHours,
}: ExpansionTabProps): JSX.Element {
  const { active, setActive } = useSharedTabState();
  const ExpansionPanelSummary = withStyles({
    root: {
      paddingLeft: '0',
      paddingRight: '0',
      margin: '0 !important',
      transition: 'all .3s var(--cubic-bezier)',
      minHeight: '56px !important',
    },
    content: {
      minHeight: '32px !important',
    },
    expanded: {
      margin: '0 !important',
    },
    expandIcon: {
      'margin': '0 !important',
      'padding': '0',
      'width': '24px',
      'height': '24px',
      'textAlign': 'center',
      '&$expanded': {
        transform: 'rotate(90deg)',
      },
    },
  })(MuiExpansionPanelSummary);

  const ExpansionPanel = withStyles({
    root: {
      'boxShadow': '0px 1px 4px 0px rgba(0, 0, 0, 0.2)',
      'backgroundColor': '#fff',
      'margin': '0 auto 8px',
      'minWidth': 'auto',
      'width': '100%',
      'borderRadius': '8px !important',
      'padding': '0 12px',
      '&$expanded': {
        margin: '0 auto 8px !important',
        border: '2px solid #0C6E1E',
      },
      '&:before': {
        opacity: 0,
      },
    },
    expanded: {
      margin: 'auto',
    },
  })(MuiExpansionPanel);

  const ExpansionPanelDetails = withStyles(() => ({
    root: {
      padding: '0px',
      fontWeight: 400,
    },
  }))(MuiExpansionPanelDetails);

  const ExpandMoreIcon = () => (
    <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.08285 5.45976C7.37264 5.7681 7.37264 6.2319 7.08285 6.54024L2.21083 11.724C1.68351 12.2851 0.700195 11.9334 0.700195 11.1838V0.816227C0.700195 0.0665766 1.68351 -0.28508 2.21083 0.275986L7.08285 5.45976Z"
        fill="#0C6E1E"
      />
    </svg>
  );

  return (
    <ExpansionPanel key={`panel-${tab.name}`} expanded={active === index}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        onClick={() => {
          if (active !== index) {
            setActive(index);
            window._waEvents.push('trackEvent', {
              page: {
                action: `accordion: ${tab.name}`,
              },
            });
          }
        }}
      >
        <img src={tab.icon} className="inline-block mr-3 h-8" alt="" />
        <h3 className="font-16 leading-32 font-bold">{tab.name}</h3>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <div id="zip-form-container" className="bg-white w-full">
          <Zipform
            qaSection={qaSection}
            insuranceType={tab.hero.type}
            position={position}
            serviceHours={serviceHours}
          />
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}

ExpansionTab.defaultProps = defaultProps;
export default ExpansionTab;
